import React from "react";
import Footer from "../components/footor";
import logo from "../assets/9.png";
import Navbar from "../components/navigationbar";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Import the CSS file for styling
import { Helmet } from "react-helmet";
import camel from "../assets/camel.jpg";
import team1 from "../assets/team/PHOTO-2023-07-31-11-58-07.jpg";
import team2 from "../assets/team/PHOTO-2023-07-31-11-58-08.jpg";
import team3 from "../assets/team/PHOTO-2023-07-31-11-58-09.jpg";
import team4 from "../assets/team/PHOTO-2023-07-31-11-58-09_1.jpg";
import team5 from "../assets/team/PHOTO-2023-07-31-11-58-10.jpg";
import team6 from "../assets/team/PHOTO-2023-07-31-11-58-11.jpg";
import team7 from "../assets/team/irrsahd.jpeg";

export default function About() {
  const teamPhotos = [team1, team2, team3, team4, team5, team6];
  const teamPhoto3 = [team7];
  const memenbers = [
    "anitha aliyar",
    "shahinsha vallooran",
    "badhusha",
    "sabeena tk",
    "MAHINSHA  vallooran",
    "robin pathrose",
    "irrsahd.jpeg",
  ];

  const memenber2 = ["AHMED IRSHAD"];

  return (
    <>
      <Helmet>
        <title>
          {" "}
          About Us | Visit Visa For Dubai For 3 Months |Kingsland Travel &
          Tourism
        </title>
        <meta
          name="description"
          content="Get your UAE tourist visa with attractive offers. Kingsland Travel & Tourism provides the best travel services."
        />

        <link rel="icon" href={logo} type="image/x-icon" />

        <link rel="canonical" href="https://thekingslandtravels.com/about" />
      </Helmet>
      <Navbar />
      <div className="about-container">
        {/* Left-side image */}
        <img
          className="about-image"
          src={camel}
          alt="Kɪɴɢꜱʟᴀɴᴅ ᴛʀᴀᴠᴇʟ & ᴛᴏᴜʀꜱɪᴍ"
        />

        {/* Right-side content */}
        <div className="about-content">
          <div className="company-location"></div>
          <div className="company-description ">
            <h1 className="font-bold">
              Welcome to{" "}
              <span className="blue">Kingsland Travel & Tourism L.L.C!</span>
            </h1>
            <h2 className="red">United Arab Emirates</h2>
            <p>
              We are a leading travel agency based in the UAE, providing a
              comprehensive range of travel and tour services. Our dedicated
              team ensures an exceptional travel experience for our clients,
              making every journey memorable and hassle-free.
            </p>
            <h2 className="font-bold blue">Visa Services</h2>
            <p>
              We facilitate visa processing for various destinations, including
              Oman, China, Malaysia, Turkey, Bahrain, Thailand, Singapore, and
              Qatar. Our visa assistance ensures a smooth and hassle-free
              application process for your chosen destination.
            </p>
            <h2 className="font-bold blue">Travel Packages</h2>
            <p>
              Explore our variety of travel packages, catering to different
              interests and preferences. Whether you seek leisure trips,
              adventure tours, or cultural experiences, our packages are
              designed to meet your specific needs.
            </p>

            <p>
              At Kingsland Travel & Tourism, customer satisfaction is our top
              priority. We strive to provide personalized service and attention
              to detail, ensuring that your travel plans are executed
              seamlessly. Whether you are an individual traveler, a family, or a
              corporate group, we are here to make your travel dreams a reality.
            </p>
            <p>
              For any inquiries or to book your next trip, please don't hesitate
              to get in touch with our friendly team. We look forward to serving
              you and being a part of your travel adventures!
            </p>
          </div>
        </div>
      </div>
      <section>
        <div className="team-photos  ">
          <h2 className="font-bold blue text-center">Meet Our Team</h2>
          <div className="team-gallery display-flex">
          {teamPhotos.map((photo, index) => (
              <div className="card" key={index}>
                <div className="zoom-center">
                  <LazyLoadImage
                    className="team-photo"
                    src={photo}
                    alt={`Team Member - ${memenbers[index]}`}
                    effect="blur"
                    threshold={300}
                  />
                </div>
                <h3 className="names">{memenbers[index]}</h3>
                <p>Travel agent</p>
              </div>
            ))}
          </div>
          <div className="team-gallery display-flex">
          {teamPhoto3.map((photo, index) => (
              <div className="card" key={index}>
                <div className="zoom-center">
                  <LazyLoadImage
                    className="team-photo"
                    src={photo}
                    alt={`Team Member - ${memenber2[index]}`}
                    effect="blur"
                    threshold={300}
                  />
                </div>
                <h3 className="names">{memenber2[index]}</h3>
                <p>Travel agent</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
