import React, { useState, useEffect } from "react";
import logo from "./assets/9.png";
import navfrom from "./assets/dubai-compressed.mp4";
import Cards from "./components/cards";
import Bannercard from "./components/bannercard";
import Aboutcard from "./components/aboutcard";
import Pakages from "./components/pakages";
import Navbar from "./components/navbar";
import { Helmet } from "react-helmet";
import videoPoster from "./assets/snap.png";
import Footer from "./components/footor";
import "./App.css";

function App() {
  // const [navbarVisible, setNavbarVisible] = useState(false);
  const [showTypingAnimation, setShowTypingAnimation] = useState(false);

  useEffect(() => {
    // Show typing animation after a short delay
    const typingAnimationTimeout = setTimeout(() => {
      setShowTypingAnimation(true);
    }, 500);

    // Clean up the timeout when the component unmounts
    return () => {
      clearTimeout(typingAnimationTimeout);
    };
  }, []);
  function openWhatsApp() {
    // Replace with the actual phone number you want to contact via WhatsApp
    var phoneNumber = "971582825292"; // Replace with the WhatsApp number including UAE country code

    // Construct the WhatsApp URL
    var whatsappURL = "https://api.whatsapp.com/send?phone=" + phoneNumber;

    // Open WhatsApp in a new tab or window
    window.open(whatsappURL, "_blank");
  }

  return (
    <>
      {" "}
      <Helmet>
        <title>UAE Tourist Visa - Kingsland Travel & Tourism</title>
        <meta
          name="description"
          content="Get your UAE tourist visa with attractive offers. Kingsland Travel & Tourism provides the best travel services."
        />

       {/* Update favicon links */}
  <link rel="icon" href={logo} type="image/png" />
  <link rel="shortcut icon" href={logo} type="image/png" />
  <link rel="apple-touch-icon" href={logo} />
  {/* Add more specific favicon sizes */}
  <link rel="icon" type="image/png" sizes="16x16" href={logo} />
  <link rel="icon" type="image/png" sizes="32x32" href={logo} />
  <link rel="icon" type="image/png" sizes="192x192" href={logo} />

        {/* Add a canonical tag */}
        <link rel="canonical" href="https://thekingslandtravels.com" />
      </Helmet>
      <Navbar />
      <main>
        <section className="banner">
          <div className="video-container">
            <video
              className="video-background"
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              poster={videoPoster}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            >
              <source src={navfrom} type="video/mp4" />
            </video>
            <div className="slide-content font-extrabold">
              <h2
                className={`font-extrabold ${
                  showTypingAnimation ? "typing-animation" : ""
                }`}
              >
                UAE TOURIST VISA WITH ATTRACTIVE OFFERS
              </h2>
              <p> Kingsland Travel & Tourism</p>
              <button onClick={openWhatsApp}>Contact Us</button>
            </div>
          </div>
        </section>
        <h2 className="text-center font-extrabold blue">Our Services</h2>
        <Cards />
        <Aboutcard />
        <Bannercard />
        <Pakages />
      </main>
      <Footer />
    </>
  );
}

export default App;
