import React from "react";
import Navbar from "../components/navigationbar";
import Packages from "../components/pakages";
import { Helmet } from "react-helmet";
import Cards from "../components/cards";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import logo from "../assets/9.png";
// Import the CSS file for styling
import camel from "../assets/camel.jpg";
import team1 from "../assets/c/1 (1).jpg";
import team2 from "../assets/c/1 (2).jpg";
import team3 from "../assets/c/1 (3).jpg";
import team4 from "../assets/c/1 (4).jpg";
import team5 from "../assets/c/1 (5).jpg";
import team6 from "../assets/c/1 (6).jpg";
import team7 from "../assets/c/1 (7).jpg";
import team8 from "../assets/c/1 (8).jpg";
import team9 from "../assets/c/1 (9).jpg";
import team10 from "../assets/c/1 (10).jpg";
import team11 from "../assets/c/1 (11).jpg";
import team12 from "../assets/c/1 (12).jpg";
import team13 from "../assets/c/1 (13).jpg";
import team14 from "../assets/c/1 (14).jpg";

export default function About() {
  // Create an array with the image paths
  const images = [
    { src: team1, alt: "Team 1" },
    { src: team2, alt: "Team 2" },
    { src: team3, alt: "Team 3" },
    { src: team4, alt: "Team 4" },
    { src: team5, alt: "Team 5" },
    { src: team6, alt: "Team 6" },
    { src: team7, alt: "Team 7" },
    { src: team8, alt: "Team 8" },
    { src: team9, alt: "Team 9" },
    { src: team10, alt: "Team 10" },
    { src: team11, alt: "Team 11" },
    { src: team12, alt: "Team 12" },
    { src: team13, alt: "Team 13" },
    { src: team14, alt: "Team 14" },
  ];

  return (
    <>
      {" "}
      <Helmet>
        <title>
          Best Travel Agency In Dubai | Best Travel Agency In UAE | Kingsland
          Travel & Tourism
        </title>
        <meta
          name="description"
          content="Get your UAE tourist visa with attractive offers. Kingsland Travel & Tourism provides the best travel services."
        />

        <link rel="icon" href={logo} type="image/x-icon" />

        <link rel="canonical" href="https://thekingslandtravels.com/services" />
      </Helmet>
      <Navbar />
      <h2 className="text-center font-extrabold blue">Our Services</h2>
      <Cards />
      <h2 className="text-center">Our Tour and Visa Pakages</h2>
      <section className="service-cards">
        {images.map((image, index) => (
           <div className="service-card" key={index}>
           <LazyLoadImage
             src={image.src}
             alt={image.alt}
             effect="blur"
             placeholderSrc={image.src} // You can use a smaller version of the image as placeholder
             threshold={300}
             className="service-card-image"
           />
         </div>
        ))}
      </section>
      <Packages />
    </>
  );
}
