import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import App from "./App";
import About from "./about/about";
import Services from "./serv/services";
import Contact from "./contact/contact";

class Navigator extends React.Component {
  render() {
    return (
      <>
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Helmet>
                  <title>UAE Tourist Visa - Kingsland Travel & Tourism</title>
                  <meta name="description" content="Get your UAE tourist visa with attractive offers. Kingsland Travel & Tourism provides the best travel services." />
                  <link rel="canonical" href="https://thekingslandtravels.com" />
                </Helmet>
                <App />
              </>
            } 
          />
          <Route 
            path="/about" 
            element={
              <>
                <Helmet>
                  <title>About Us - Kingsland Travel & Tourism</title>
                  <meta name="description" content="Learn more about Kingsland Travel & Tourism's experience in providing UAE visa services and tour packages." />
                  <link rel="canonical" href="https://thekingslandtravels.com/about" />
                </Helmet>
                <About />
              </>
            } 
          />
          <Route 
            path="/services" 
            element={
              <>
                <Helmet>
                  <title>Our Services - Kingsland Travel & Tourism</title>
                  <meta name="description" content="Explore our comprehensive travel services including UAE visa processing, tour packages, and more." />
                  <link rel="canonical" href="https://thekingslandtravels.com/services" />
                </Helmet>
                <Services />
              </>
            } 
          />
          <Route 
            path="/contact" 
            element={
              <>
                <Helmet>
                  <title>Contact Us - Kingsland Travel & Tourism</title>
                  <meta name="description" content="Get in touch with Kingsland Travel & Tourism for UAE visa and travel services. Contact us today!" />
                  <link rel="canonical" href="https://thekingslandtravels.com/contact" />
                </Helmet>
                <Contact />
              </>
            } 
          />
        </Routes>
      </>
    );
  }
}

export default Navigator;
